<template>
    <div class="section-black pt-0">
        <section class="head container mt-0 p-l-50 p-r-50">
            <div class="title">{{ $t('cities.cities') }}</div>
            <div class="sub-title">{{ $t('cities.whereToFindUs') }}</div>
        </section>
        <section class="container cities mt-0 pt-0 p-l-55 p-r-55">
            <div class="row">
                <div class="col-4 city">
                    <div class="item bg">
                        <span class="underline">{{ $t('cities.unitedKingdom') }}</span>
                    </div>
                </div>
                <div class="col-4 city cursor"
                     @click="$goto('/cities/'+city.slug)"
                     v-for="(city, index) of cityList"
                     :key="index"
                     v-if="index < cityList.length - 1">
                    <div class="item" :class="city.img ? '' : 'bg'">
                        <span class="item-title">{{ city.name }}</span>
                        <img v-if="city.img" :src="city.img" alt="">
                    </div>
                </div>

                <div class="col-4 city">
                    <div class="item bg">
                        <span class="underline">{{ $t('cities.spain') }}</span>
                    </div>
                </div>

                <div class="col-4 city cursor" @click="$goto('/cities/'+cityList[cityList.length - 1].slug)">
                    <div class="item" :class="cityList[cityList.length - 1].img ? '' : 'bg'">
                        <span class="item-title">{{ cityList[cityList.length - 1].name }}</span>
                        <img v-if="cityList[cityList.length - 1].img" :src="cityList[cityList.length - 1].img" alt="">
                    </div>
                </div>

                <div class="col-4 city">
                    <div class="item bg" style="background-color: #e91a4c">
                        <span v-html="$t('cities.whereNext')"></span>
                    </div>
                </div>

            </div>
        </section>
        <section class="m-t-15">
            <img class="footer-img"  src="https://static.vitaalumnicn.com/static/vsweb/cities-1-1.png" alt="">
        </section>
    </div>
</template>

<script>

import {getCitiesAPI} from '@/apis/common'

export default {
    name: "cities",
    data() {
        return {
            cities: []
        }
    },
    computed: {
        cityList() {
            let isCn = this.$i18n.locale === 'cn'
            let list = []
            this.cities.forEach(item => {
                list.push({
                    id: item.id,
                    name: isCn ? item.name : item.enName,
                    country: isCn ? item.country : item.enCountry,
                    img: item.thumbnailUrl,
                    slug: item.slug
                })
            })
            return list
        }
    },
    created() {
        this.getCities();
    },
    methods: {
        async getCities() {
            if (this.$store.state.cities.length > 0) {
                this.cities = this.$store.state.cities;
            } else {
                await getCitiesAPI().then(res => {
                    if (res.status === 200) {
                        this.cities = res.data.data
                        this.$store.commit('setCities', this.cities)
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>

.head {
    color: #fff;
    font-family: Trade Gothic LT Std Condensed, sans-serif;
    padding: 135px 0;

    .title {
        font-size: 72px;
        font-weight: 700;
    }

    .title:before {
        margin-top: -17px;
        width: 86px;
        content: "";
        display: block;
        background-color: #e91a4c;
        position: absolute;
        height: 6px;
    }

    .sub-title {
        margin-top: 8px;
        font-size: 16px;
        font-family: Radikal Light, sans-serif;
    }
}

.cities {
    text-align: center;
    margin-top: 60px;


    .city {
        padding: 0 5px;
        position: relative;

        .bg {
            background-color: rgba(32, 32, 34, 0.65);
            padding: 0 5px;
        }

        .bg:hover {
            background-color: rgba(32, 32, 34, 1);
        }

        .item {
            position: relative;
            overflow: hidden;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            font-family: Trade Gothic LT Std Condensed, sans-serif;
            font-weight: 900;
            color: #fff;
            aspect-ratio: 1 / 1;
            line-height: 1.1;
            transition: transform 0.6s ease;

            .item-title {
                position: relative;
                z-index: 2;
            }

            .underline:before {
                width: 63px;
                content: "";
                display: block;
                background-color: #e91a4c;
                position: relative;
                height: 7px;
                bottom: -70px;
                margin: 0 auto;
            }

            img {
                top: 0;
                object-fit: cover;
                position: absolute;
                width: auto;
                height: 100%;
                -webkit-transition: -webkit-filter 0.5s ease;
                transition: -webkit-filter 0.5s ease;
                transition: filter 0.5s ease;
                -webkit-filter: brightness(0.7);
                filter: brightness(0.7);
                transition: transform 0.5s ease;
            }
        }

        .item:hover {

            img {
                -webkit-filter: brightness(1);
                filter: brightness(1);
                -webkit-animation: brightnessTransition 0.5s forwards;
                animation: brightnessTransition 0.5s forwards;
                transform: scale(1.15);
            }
        }

        @keyframes brightnessTransition {
            from {
                -webkit-filter: brightness(0.7);
                filter: brightness(0.7);
            }
            to {
                -webkit-filter: brightness(1);
                filter: brightness(1);
            }
        }

        .item img {
            -webkit-animation: brightnessTransitionOut 0.5s forwards;
            animation: brightnessTransitionOut 0.5s forwards;
        }

        @keyframes brightnessTransitionOut {
            from {
                -webkit-filter: brightness(1);
                filter: brightness(1);
            }
            to {
                -webkit-filter: brightness(0.7);
                filter: brightness(0.7);
            }
        }

        .item:hover img {
            -webkit-animation: none;
            animation: none;
        }

    }

}
</style>
